import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { DateRangePicker, Range } from 'react-date-range';
import dayjs from 'dayjs';
import { ko } from 'date-fns/locale';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import { GetRetentions } from '../../services/users/statistics/retentions.service';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import ExcelDownLoadButton from '../../components/ExcelDownload/ExcelDownLoadButton'; // theme css file

dayjs.locale('ko');

export default function AUStatistics() {
  const [type, setType] = useState<string>("daily");
  const [rows, setRows] = useState<any[]>([]);

  const [dateRange, setDateRange] = useState<Range>({
    startDate: dayjs().toDate(),
    endDate: dayjs().toDate(),
    key: 'dates',
  })

  const handleAUChange = (event: SelectChangeEvent) => {
    setType(event.target.value as string);
  };

  const onChangeDateRange = useCallback((ranges: any) => {
    setDateRange({
      startDate: ranges['dates'].startDate,
      endDate: ranges['dates'].endDate,
      key: 'dates',
    })
  }, []);

  const onClickSearchButton = useCallback(async () => {
    const startDate = dayjs(dateRange.startDate).format("YYYY-MM-DD");
    const endDate = dayjs(dateRange.endDate).format("YYYY-MM-DD");

    const aus = await GetRetentions(type, startDate, endDate);
    const newRows: any[] = [];
    Object.keys(aus).forEach((baseDate, index) => {
      const dateAU = aus[baseDate];
      newRows.push({
        id: index+1,
        date: baseDate,
        au: dateAU.AU,
        retain: dateAU.retainAU,
        retention: (dateAU.retainAU / dateAU.AU * 100).toFixed(2)
      })
    });

    setRows(newRows);
  }, [dateRange, type]);

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: '기준일',
      minWidth: 50,
    },
    {
      field: 'au',
      headerName: 'AU',
      minWidth: 100,
    },
    {
      field: 'retain',
      headerName: 'Retain',
      minWidth: 100,
    },
    {
      field: 'retention',
      headerName: 'Retention',
      minWidth: 100,
    }
  ];

  return (
    <div className="main-content">
      <div className="section-search">
        <Grid container
              direction="row"
              spacing={2}
              mb={1}>
          <Grid item>
            <DateRangePicker ranges={[dateRange]}
                             onChange={onChangeDateRange}
                             locale={ko}
                             showDateDisplay={false}
                             maxDate={dayjs().toDate()}
            />
          </Grid>

          <Grid item
                display="flex"
                flexGrow={1}
                gap={2}
                flexDirection="column">
            <Box display="flex"
                 flexDirection="row"
                 gap={2}>
              <Box minHeight={60}>
                <FormControl fullWidth>
                  <InputLabel id="au-type">검색기준</InputLabel>
                  <Select labelId="au-type"
                          id="au-type-select"
                          value={type}
                          label="검색 기준"
                          onChange={handleAUChange}
                          style={{ height: "60px"}}>
                    <MenuItem value="daily">일간</MenuItem>
                    <MenuItem value="weekly">주간</MenuItem>
                    <MenuItem value="monthly">월간</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box minHeight={60}
                   sx={{ width: 1 }}>
                <Button variant="contained"
                        onClick={onClickSearchButton}
                        fullWidth
                        style={{ height: "60px"}}>
                  검색
                </Button>
              </Box>
            </Box>

            <Box minHeight={60}
                 sx={{
                   width: 1,
                   opacity: rows.length > 0 ? 1 : 0,
                 }} >
              <ExcelDownLoadButton headers={["기준일", "AU", "Retain", "Retention"]}
                                   data={rows.map(row => [row.date, row.au, row.retain, row.retention])}
                                   excelFileName="AU통계"
                                   sheetName={`AU통계_${type}`}
                                   sx={{ width: 1, height: "60px" }} />
            </Box>
          </Grid>
        </Grid>

        <Grid container>


        </Grid>

        <Grid container>
          <Typography mb={-1}>
            <small>*한주의 시작을 "월요일"로 간주한 데이터입니다.</small></Typography>
          <div
            style={{
              height: '80vh',
              width: '100%',
              marginTop: '10px',
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 20 },
                },
              }}
            />
          </div>
        </Grid>
      </div>

    </div>);
}
