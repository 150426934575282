import api, { CoreResponse } from './api';

export const FetchAllPopupStores = async (toOpen?: boolean) => {
  try {
    const url = toOpen ? `/api/admin/v2/popup-stores?toOpen=${true}` : '/api/admin/v2/popup-stores';
    const { data } = await api.get(url);

    return data;
  } catch {
    return [];
  }
};

interface CreatePopupStoreRequest {
  title: string;
  descriptionTitle: string;
  description: string;
  address: string;
  latitude: number;
  longitude: number;
  startDate: string;
  endDate: string;
  logoImage: string;
  thumbnailImages: string[];
  monday: string;
  tuesday: string;
  wednesday: string;
  thursday: string;
  friday: string;
  saturday: string;
  sunday: string;
  isAssociated: boolean;
  isParkingAvailable: boolean;
  isFreeEntrance: boolean;
  instagramLink: string;
  officialLink: string;
  isPreReservation: boolean;
  isTopBeforeStarted: boolean;
  reservationLink: string;
  eventsTitle: string;
  events: string;
  type: string;
}

export const CreatePopupStore = async (request: CreatePopupStoreRequest) => {
  try {
    await api.post('/api/admin/v2/popup-stores', request);
    return true;
  } catch (e) {
    return false;
  }
};

export const FetchPopupStoreDetail = async (id: number) => {
  try {
    const { data } = await api.get(`/api/admin/v2/popup-stores/${id}`);

    return data;
  } catch {
    return null;
  }
}

export const UpdatePopupStore = async (id: number, request: CreatePopupStoreRequest) => {
  try {
    await api.patch(`/api/admin/v2/popup-stores/${id}`, request);
    return true;
  } catch (e) {
    return false;
  }
}

interface FetchAllPopupStoreOpenNotificationApplicationsResponse extends CoreResponse {
  popupStoreOpenNotificationApplications: OpenNotificationApplication[];
}

export interface OpenNotificationApplication {
  id: number;
  applicationDate: string;
  user: {
    id: number;
    nickname: string;
    phoneNumber: string;
    sugar: number;
  };
  popupStore: {
    id: number;
    name: string;
    startDate: string;
    description: string;
  },
}

export const FetchAllPopupStoreOpenNotificationApplications = async () => {
  try {
    const { data } = await api.get<FetchAllPopupStoreOpenNotificationApplicationsResponse>('/api/admin/v2/popup-stores/open-notification-applications');

    return data.popupStoreOpenNotificationApplications;
  } catch {
    return [];
  }
}

export interface PopupStoreRanking {
  id: number;
  locationId: number;
  name: string;
  address: string;
  latitude: number;
  longitude: number;
  logoImage: string;
  thumbnailImages: string[];
  isAssociated: boolean;
  startDate: string;
  endDate: string;
  postCount: number;
  viewCount: number;
  registeredDate: string;
  reservationLink: string;
  isPreReservation: boolean;
}

interface FetchPopupStoreRankingsResponse extends CoreResponse {
  popupStores: PopupStoreRanking[];
}

export const FetchPopupStoreRankings = async (): Promise<PopupStoreRanking[]> => {
  try {
    const { data } = await api.get<FetchPopupStoreRankingsResponse>('/api/v2/popup-stores/rankings');

    return data.popupStores;
  } catch {
    return [];
  }
}
