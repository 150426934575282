import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import ExcelDownLoadButton from "components/ExcelDownload/ExcelDownLoadButton";
import { useEffect, useState } from "react";
import api from "services/api";

interface UserSurvey {
  id: number;
  favorites: string;
  companions: string;
  locations: string;
  ageRange: string;
  gender: 'male' | 'female' | '';
  createdAt: string;
}

const toStringGender = (enGender: string) => {
  switch (enGender) {
    case 'male':
      return "남성";
    case 'female':
      return "여성";
    default:
      return "";
  }
}

export default function UserSurveys() {
  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    async function fetchSurveys() {
      try {
        const { data } = await api.get<{
          statusCode: number;
          surveys: UserSurvey[];
        }>("/api/admin/v2/users/surveys");
        
        const newRows = data.surveys?.map((survey: UserSurvey) => ({
          id: survey.id,
          favorites: survey.favorites,
          companion: survey.companions,
          location: survey.locations,
          ageRange: survey.ageRange,
          gender: toStringGender(survey.gender),
        }));
        setRows(newRows);

      } catch {
        setRows([]);
      }
    }

    fetchSurveys();
  }, []);

  const columns: GridColDef[] = [
    {
      field: "location",
      headerName: "주 방문지역"
    },
    {
      field: "companion",
      headerName: "동행인",
    },
    {
      field: "ageRange",
      headerName: "연령대",
    },
    {
      field: "gender",
      headerName: "성별",
    },
    {
      field: "favorites",
      headerName: "관심사",
    },
  ];

  return (
    <div className="main-content">
      <Grid container>
        <ExcelDownLoadButton headers={["주 방문지역", "동행인", "연령대", "성별", "관심사"]}
                             data={rows.map(row => [row.location, row.companion, row.ageRange, row.gender, row.favorites])}
                             excelFileName="사용자_설문"
                             sheetName="사용자_설문" />
                          
      </Grid>

      <Grid container
            mt={1}>
        <Grid item
              xs={12}>
          <Box width='100%'
               height='75vh'>
            <DataGrid columns={columns}
                      rows={rows}
                      initialState={{
                        pagination: {
                          paginationModel: { page: 0, pageSize: 20 }
                        },
                      }}
                      pageSizeOptions={[5, 10]} />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
