import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Button, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material';
import dayjs from 'dayjs';

import "./modal.css";
import Swal from 'sweetalert2'
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { FetchAllPopupStores } from 'services/popup-stores.service';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { GetPresignedUrl, PresignedUrl, UploadFileUsingPresignedUrl } from 'services/uploads.service';
import { CloudUpload } from '@material-ui/icons';
import { VisuallyHiddenInput } from 'elements/v2/VisuallyHiddenInput/VisuallyHiddeninput';
import AdminDatePicker from 'elements/v2/AdminDatePicker/AdminDatePicker';
import api from 'services/api';
dayjs.locale('ko');

interface AdBannersModalProps {
  open: boolean;
  onClose: () => void;
  selectedBanner?: any | null;
}

export default function AdBannersModal({
  open,
  onClose,
  selectedBanner,
}: AdBannersModalProps) {
  const navigate: NavigateFunction = useNavigate();
  const [name, setName] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [linkType, setLinkType] = useState<string>("NONE");
  const [popupStores, setPopupStores] = useState<any[]>([]);
  const [selectedPopupStoreId, setSelectedPopupStoreId] = useState<number | null>(null);
  const [externalLink, setExternalLink] = useState<string>("");
  const [imageUrl, setImageUrl] = useState<string>("");
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(null);
  const [startHour, setStartHour] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(null);
  const [endHour, setEndHour] = useState<string | null>(null);
  const [bannerText, setBannerText] = useState<string>("");

  useEffect(() => {
    async function fetchAllPopups() {
      const fetchedPopupStores = await FetchAllPopupStores();
      const nowDayjs = dayjs();

      setPopupStores(fetchedPopupStores.filter((popupStore: any) => popupStore.endDate >= nowDayjs.format('YYYY-MM-DD')));
    }

    fetchAllPopups();
  }, []);

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) {
        return [];
      }

      Swal.showLoading();
      for await (const file of e.target.files) {
        const presignedUrl: PresignedUrl = await GetPresignedUrl();
  
        const isSuccess: boolean = await UploadFileUsingPresignedUrl({
          url: `https://s3.ap-northeast-2.amazonaws.com/${presignedUrl.fields.bucket}`,
          'X-Amz-Algorithm': presignedUrl.fields['X-Amz-Algorithm'],
          'X-Amz-Credential': presignedUrl.fields['X-Amz-Credential'],
          'X-Amz-Signature': presignedUrl.fields['X-Amz-Signature'],
          'X-Amz-Date': presignedUrl.fields['X-Amz-Date'],
          bucket: presignedUrl.fields.bucket,
          key: presignedUrl.fields.key,
          Policy: presignedUrl.fields.Policy,
          'Content-Type': file.type,
          file,
        });
  
        if (!isSuccess) {
          Swal.fire('이미지 업로드 실패', '이미지 업로드에 실패했습니다. 다시 시도해주세요.', 'error');
          return [];
        }
  
        setImageUrl(presignedUrl.uploadedUrl);
      }
      Swal.close();
    }

  useEffect(() => {
    if (!selectedBanner) {
      setName("");
      setType("");
      setLinkType("NONE");
      setSelectedPopupStoreId(null);
      setExternalLink("");
      setImageUrl("");
      setStartDate(null);
      setStartHour(null);
      setEndDate(null);
      setEndHour(null);
      setBannerText("");
      return;
    }

    setName(selectedBanner.name);
    setType(selectedBanner.type);
    setLinkType(selectedBanner.linkType);
    setSelectedPopupStoreId(selectedBanner.popupStoreId);
    setExternalLink(selectedBanner.externalLink);
    setImageUrl(selectedBanner.imageUrl);
    setStartDate(dayjs(selectedBanner.startDatetime));
    setStartHour(dayjs(selectedBanner.startDatetime).format('HH'));
    setEndDate(dayjs(selectedBanner.endDatetime));
    setEndHour(dayjs(selectedBanner.endDatetime).format('HH'));
    setBannerText(selectedBanner.bannerText);

  }, [selectedBanner]);

  return (
    <Modal open={open}
           onClose={onClose}
           aria-labelledby="ad-banner-modal"
           aria-describedby="ad-banner-modal-description">
      <Box sx={{
             position: 'absolute' as 'absolute',
             top: '50%',
             left: '50%',
             transform: 'translate(-50%, -50%)',
             width: "60%",
             bgcolor: 'background.paper',
             border: '2px solid #000',
             boxShadow: 24,
             p: 4,
             maxHeight: '80vh',
              overflowY: 'auto',
           }}>
        <Box>
          <Grid container
                mb={5}>
            <Grid item
                  xs={12}>
              <h2>광고배너 등록/수정</h2>
            </Grid>
          </Grid>

          <Grid container
                spacing={4}>
            <Grid item
                  xs={6}>
              <FormControl fullWidth>
                <TextField required
                           fullWidth
                           id="title"
                           label="캠페인명"
                           onChange={(e) => setName(e.target.value)}
                           value={name} />
              </FormControl>
            </Grid>

            <Grid item
                  xs={6}
                  sx={{ marginTop: "1px" }}>
              <FormControl>
                <FormLabel id="type">캠페인 타입</FormLabel>
                <RadioGroup aria-labelledby="type"
                            defaultValue=""
                            name="type-radio"
                            row
                            value={type}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setType(e.target.value)}>
                  <FormControlLabel value="MAIN"
                                    control={<Radio />}
                                    label="메인" />
                  <FormControlLabel value="POPUP_STORE_HOME"
                                    control={<Radio />}
                                    label="장소_홈" />
                  <FormControlLabel value="SPLASH"
                                    control={<Radio />}
                                    label="스플래쉬" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container
                spacing={4}
                mt={.5}>
            <Grid item
                  xs={6}>
              <FormControl>
                <FormLabel id="link-type">랜딩 타입</FormLabel>
                <RadioGroup aria-labelledby="link-type"
                            defaultValue=""
                            name="type-radio-landing"
                            row
                            value={linkType}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLinkType(e.target.value)}>
                  <FormControlLabel value="POPUP_STORE"
                                    control={<Radio />}
                                    label="장소상세페이지" />
                  <FormControlLabel value="EXTERNAL"
                                    control={<Radio />}
                                    label="외부링크" />
                  <FormControlLabel value="NONE"
                                    control={<Radio />}
                                    label="없음" />
                </RadioGroup>
              </FormControl>
            </Grid>


            <Grid item
                  xs={6}>
              {linkType === "POPUP_STORE" && (
                <FormGroup row className="selection" style={{ width: "100%" }}>
                <Select value={selectedPopupStoreId}
                        onChange={(e) => setSelectedPopupStoreId(e.target.value as number)}
                        style={{ width: '100%' }}
                        displayEmpty>
                  {popupStores && popupStores.length > 0
                    ? popupStores.map((popupStore) => (
                        <MenuItem key={popupStore.id}
                                  className="menu-item"
                                  value={popupStore.id}
                                  style={{ width: '100%' }}
                                  >
                          {popupStore.name}
                        </MenuItem>
                      ))
                    : ''}
                </Select>
              </FormGroup>
              )}

              {linkType === "EXTERNAL" && (
                <FormControl fullWidth>
                  <TextField fullWidth
                             id="external-link"
                             label="외부링크"
                             value={externalLink} 
                             onChange={(e) => setExternalLink(e.target.value)} />
                             
                </FormControl>
              )}
              
            </Grid>
          </Grid>


          <Grid container
                spacing={4}
                mt={.5}>
            <Grid item
                  xs={4}>
              <Button component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      startIcon={<CloudUpload />}
                      fullWidth
                      color={'secondary'}
                      sx={{
                        width: '100%'
                      }}
                    >
                      광고 이미지
                      <VisuallyHiddenInput
                        type='file'
                        onChange={async (e: React.ChangeEvent<HTMLInputElement>) => handleImageUpload(e)}
                      />
                    </Button>
                    
              <Box mt={.5}>
                {imageUrl && (
                  <img src={imageUrl}
                       alt="이미지"
                       style={{ width: '100%' }} />
                )}
              </Box>
            </Grid>

            <Grid item
                  xs={4}>
              <AdminDatePicker label="시작일"
                               value={startDate}
                               onChange={(dayjs) => setStartDate(dayjs)}></AdminDatePicker>
              <TextField label="시작 시간"
                          type="number"
                          value={startHour ?? ""}
                          fullWidth
                          InputProps={{ inputProps: { min: 0, max: 24 } }}
                          onChange={(e) => setStartHour(e.target.value)} 
                          sx={{
                            marginTop: "10px",
                          }} />
            </Grid>

            <Grid item
                  xs={4}>
              
              <AdminDatePicker label="종료일"
                                value={endDate}
                                onChange={(dayjs) => setEndDate(dayjs)}></AdminDatePicker>
              <TextField label="종료 시간"
                          type="number"
                          value={endHour ?? ""}
                          fullWidth
                          InputProps={{ inputProps: { min: 0, max: 24 } }}
                          onChange={(e) => setEndHour(e.target.value)}
                          sx={{
                          marginTop: "10px",
                          }} />
            </Grid>
          </Grid>

          <Grid container mt={.5}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField required
                           fullWidth
                           id="link"
                           label="배너 텍스트"
                            onChange={(e) => setBannerText(e.target.value)}
                           value={bannerText} />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container mt={1} spacing={2}>
            <Grid item xs={6}>
              <Button variant="contained"
                      color="error"
                      sx={{ width: '100%' }}
                      onClick={() => {
                        Swal.fire({
                          title: '삭제',
                          text: '정말로 삭제하시겠습니까?',
                          icon: 'warning',
                          showCancelButton: true,
                          customClass: {
                            container: 'swal-container',
                            popup: 'my-swal-popup'
                          },
                        }).then((res) => {
                          if (res.isConfirmed) {
                            api.delete(`/api/admin/v2/ad-banners/${selectedBanner.id}`);
                            Swal.fire({
                              title: '성공',
                              text: '광고배너가 삭제되었습니다.',
                              customClass: {
                                container: 'swal-container',
                                popup: 'my-swal-popup'
                              },
                              icon: 'success',
                            }).then((res) => {
                              if (res.isConfirmed) {
                                navigate(0);
                              }
                            });
                          }
                        });
                      }}>삭제</Button>
            </Grid>

            <Grid item xs={6}>
              <Button variant="contained"
                      color="primary"
                      sx={{ width: '100%' }}
                      onClick={ async () => {
                        if (!name || !type || !linkType || !startDate || !endDate || !imageUrl || !bannerText) {
                          Swal.fire({
                            title: '입력값 오류',
                            text: '필수 입력값을 모두 입력해주세요.',
                            icon: 'error',
                            customClass: {
                              container: 'swal-container',
                              popup: 'my-swal-popup'
                            },
                          })
                          return;
                        }

                        if (linkType === "POPUP_STORE" && !selectedPopupStoreId) {
                          Swal.fire({
                            title: '입력값 오류',
                            text: '팝업스토어를 선택해주세요.',
                            icon: 'error',
                            customClass: {
                              container: 'swal-container',
                              popup: 'my-swal-popup'
                            },
                          })
                          return;
                        } else if (linkType === "EXTERNAL" && !externalLink) {
                          Swal.fire({
                            title: '입력값 오류',
                            text: '외부링크를 입력해주세요.',
                            icon: 'error',
                            customClass: {
                              container: 'swal-container',
                              popup: 'my-swal-popup'
                            },
                          });
                          return;
                        }

                        try {
                          api.post(
                            "/api/admin/v2/ad-banners",
                            {
                              title: name,
                              type: type,
                              linkType: linkType,
                              popupStoreId: selectedPopupStoreId,
                              externalLink: externalLink,
                              startDate: startDate?.format('YYYY-MM-DD') ?? "",
                              endDate: endDate?.format('YYYY-MM-DD') ?? "",
                              startHour: startHour ?? "",
                              endHour: endHour ?? "",
                              imageUrl: imageUrl,
                              bannerText: bannerText,
                            }
                          );

                          Swal.fire({
                              title: '성공',
                              text: '광고배너가 성공적으로 등록되었습니다.',
                              customClass: {
                                container: 'swal-container',
                                popup: 'my-swal-popup'
                              },
                              icon: 'success',
                            }).then((res) => {
                              if (res.isConfirmed) {
                                navigate(0);
                              }
                            });
                            
                        } catch (e) {
                        }
                        
                      } }>저장</Button>
            </Grid>
          </Grid>
        </Box>

      </Box>
    </Modal>
  );
}
