import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Button, FormControl, Grid, TextField } from '@mui/material';
import AdminDatePicker from '../elements/v2/AdminDatePicker/AdminDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import Divider from '@mui/material/Divider';
import api from '../services/api';
import "./modal.css";
import Swal from 'sweetalert2';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Notice } from '../services/notices/notices.service';

interface NoticeModalProps {
  open: boolean;
  onClose: () => void;
  noticeId?: number | null;
}

export default function NoticeModal({
  open,
  onClose,
  noticeId
}: NoticeModalProps) {
  const [title, setTitle] = useState<string>("");
  const [link, setLink] = useState<string>("");
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [startHour, setStartHour] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [endHour, setEndHour] = useState<string | null>(null);
  const navigate: NavigateFunction = useNavigate();

  useEffect(() => {
    async function fetchNotice() {
      const { data } = await api.get(`/api/admin/v2/notices/${noticeId}`);
      const notice = data.notice as Notice;

      setTitle(notice.title);
      setLink(notice.link);

      setStartDate(dayjs(notice.startDate));
      if (notice.startHour) {
        setStartHour(notice.startHour);
      }

      setEndDate(dayjs(notice.endDate));
      if (notice.endHour) {
        setEndHour(notice.endHour);
      }
    }

    if (noticeId) {
      fetchNotice();
    } else {
      setTitle("");
      setLink("");
      setStartDate(null);
      setStartHour(null);
      setEndDate(null);
      setEndHour(null);
    }
  }, [noticeId]);

  return (
    <Modal open={open}
           onClose={onClose}
           aria-labelledby="notice-modal"
           aria-describedby="notice-modal-description">
      <Box sx={{
             position: 'absolute' as 'absolute',
             top: '50%',
             left: '50%',
             transform: 'translate(-50%, -50%)',
             width: "60%",
             bgcolor: 'background.paper',
             border: '2px solid #000',
             boxShadow: 24,
             p: 4,
           }}>
        <Box>
          <Grid container
                mb={5}>
            <Grid item
                  xs={12}>
              <h2>공지사항 등록/수정</h2>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item
                  xs={12}>
              <FormControl fullWidth>
                <TextField required
                           fullWidth
                           id="title"
                           label="공지사항 제목"
                           onChange={(e) => setTitle(e.target.value)}
                           value={title} />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container
                mt={1}>
            <Grid item
                  xs={12}>
              <FormControl fullWidth>
                <TextField required
                           fullWidth
                           id="link"
                           label="링크"
                            onChange={(e) => setLink(e.target.value)}
                           value={link} />
              </FormControl>
            </Grid>
          </Grid>

          <Divider />

          <Grid container
                gap={1}>
            <Grid item
                  xs={5}>
              <AdminDatePicker label="시작일"
                               value={startDate}
                               onChange={(dayjs: Dayjs) => setStartDate(dayjs)}></AdminDatePicker>
              <TextField label="시작 시간"
                          type="number"
                          value={startHour ?? ""}
                          fullWidth
                          InputProps={{ inputProps: { min: 0, max: 24 } }}
                          onChange={(e) => setStartHour(e.target.value)} 
                          sx={{
                           marginTop: "10px",
                          }} />
            </Grid>

            <Grid item
                  xs={5}>
              <AdminDatePicker label="종료일"
                               
                               value={endDate}
                               onChange={(dayjs: Dayjs) => setEndDate(dayjs)}></AdminDatePicker>
              <TextField label="종료 시간"
                         type="number"
                         value={endHour ?? ""}
                         fullWidth
                         InputProps={{ inputProps: { min: 0, max: 24 } }}
                         onChange={(e) => setEndHour(e.target.value)}
                         sx={{
                          marginTop: "10px",
                         }} />
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Grid container
                mt={5}>
            <Grid item
                  xs={12}>
              <Button variant="contained"
                      color="primary"
                      onClick={ async () => {
                        if (!title || !link || !startDate || !endDate) {
                          Swal.fire({
                            title: '필수값을 입력해주세요',
                            text: '제목, 링크, 시작/종료일은 필수입니다',
                            customClass: {
                              container: 'swal-container',
                              popup: 'my-swal-popup'
                            },
                            icon: 'error',
                          })
                          return;
                        }

                        try {
                          if (noticeId) {
                            await api.put(`/api/admin/v2/notices/${noticeId}`, {
                              title,
                              link,
                              startDate: startDate.format("YYYY-MM-DD"),
                              startHour,
                              endDate: endDate.format("YYYY-MM-DD"),
                              endHour,
                            });

                            Swal.fire({
                              title: '공지사항 수정',
                              text: '공지사항 수정에 성공했습니다.',
                              customClass: {
                                container: 'swal-container',
                                popup: 'my-swal-popup'
                              },
                              icon: 'success',
                            }).then((res) => {
                              if (res.isConfirmed) {
                                navigate(0);
                              }
                            })
                          } else {
                            await api.post("/api/admin/v2/notices", {
                              title,
                              link,
                              startDate: startDate.format("YYYY-MM-DD"),
                              startHour,
                              endDate: endDate.format("YYYY-MM-DD"),
                              endHour,
                            });

                            Swal.fire({
                              title: '공지사항 저장',
                              text: '공지사항 저장에 성공했습니다.',
                              customClass: {
                                container: 'swal-container',
                                popup: 'my-swal-popup'
                              },
                              icon: 'success',
                            }).then((res) => {
                              if (res.isConfirmed) {
                                navigate(0);
                              }
                            })
                          }
                        } catch {
                          Swal.fire({
                            title: '오류 발생',
                            text: '알수없는 오류가 발생했습니다.',
                            customClass: {
                              container: 'swal-container',
                              popup: 'my-swal-popup'
                            },
                            icon: 'error',
                          })
                        }
                      }}
              >저장</Button>

              <Button variant="contained"
                      color="error"
                      sx={{
                        marginLeft: "10px",
                      }}
                      onClick={async () => {
                        if (!noticeId) {
                          return;
                        }

                        await api.delete(`/api/admin/v2/notices/${noticeId}`);
                        Swal.fire({
                          title: '공지사항 삭제',
                          text: '공지사항 삭제에 성공했습니다.',
                          customClass: {
                            container: 'swal-container',
                            popup: 'my-swal-popup'
                          },
                          icon: 'success',
                        }).then((res) => {
                          if (res.isConfirmed) {
                            navigate(0);
                          }
                        });
                      }}>삭제</Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
}
