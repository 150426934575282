import axios from 'axios';

import { CrawledPopupStoreDetail } from './crawls.type';

interface CrawledPopupStoreResponse {
  popupDetail: CrawledPopupStoreDetail;
}

export const CrawlPopupStore = async (id: number): Promise<CrawledPopupStoreDetail | null> => {
  try {
    const { data } = await axios.get<CrawledPopupStoreResponse>(`http://13.124.2.55:8088/popup-stores/${id}`);

    return data.popupDetail
  } catch {
    return null;
  }
}
