import api from '../../api';

export interface RetentionAU {
  AU: number;
  retainAU: number;
}

export interface Retention {
  aus: {
    [baseDate: string]: RetentionAU;
  };
}

export const GetRetentions = async (
  type: string,
  from: string,
  to: string,
) => {
  const { data } = await api.get<Retention>(`/api/admin/v2/users/all/statistics/rententions?type=${type}&from=${from}&to=${to}`);

  return data.aus;
}
