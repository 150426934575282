import { Grid } from "@material-ui/core";
import { Box, Button } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useEffect } from 'react';
import { FetchNotices, Notice } from '../../services/notices/notices.service';
import NoticeModal from '../../modals/NoticeModal';

const switchStatus = (status: string) => {
  switch (status) {
    case "active":
      return "노출";
    case "inactive":
      return "비노출 (종료)";
    case "before_start":
      return "비노출 (시작 전)";
    default:
      return "알 수 없음";
  }
}

const translateDatetime = (date: string, hour: string | null) => {
  if (hour) {
    return `${date} (${hour}시)`;
  }

  return date;
}

export default function Notices() {
  const [rows, setRows] = React.useState<any[]>([]);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [selectedNoticeId, setSelectedNoticeId] = React.useState<number | null>(null);

  const onClickCloseModal = () => {
    setModalOpen(false);
    setSelectedNoticeId(null);
  }

  useEffect(() => {
    async function fetchAllNotices() {
      const notices = await FetchNotices();
      setRows(notices.map((notice: Notice) => ({
        id: notice.id,
        title: notice.title,
        link: notice.link,
        startDate: translateDatetime(notice.startDate, notice.startHour),
        endDate: translateDatetime(notice.endDate, notice.endHour),
        status: switchStatus(notice.status),
      })));
    }

    fetchAllNotices();
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 50,
    },
    {
      field: 'title',
      headerName: '공지사항',
      minWidth: 200,
    },
    {
      field: 'link',
      headerName: '링크',
      minWidth: 200,
    },
    {
      field: 'startDate',
      headerName: '시작일',
      minWidth: 150,
    },
    {
      field: 'endDate',
      headerName: '종료일',
      minWidth: 150,
    },
    {
      field: 'status',
      headerName: '상태',
      minWidth: 50,
    },
    {
      field: "actions",
      headerName: "",
      minWidth: 100,
      renderCell: (params) => (
        <strong>
          <Button variant="contained"
                  color="secondary"
                  size="small"
                  onClick={(param) => {
                    setSelectedNoticeId(params.row.id);
                    setModalOpen(true);
                  }}>관리</Button>
        </strong>
      ),
    },
  ];

  return (
    <div className="main-content">
      <Grid container>
        <Grid item>
          <Button variant="contained"
                  color="primary"
                  sx={{
                    width: "100%",
                    height: "100%",
                  }}
                  onClick={() => setModalOpen(true)}>
            추가하기
          </Button>
        </Grid>
      </Grid>

      <Box>
        <div
          style={{
            height: '80vh',
            width: '100%',
            marginTop: '10px',
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 20 },
              },
            }}
          />
        </div>
      </Box>

      <NoticeModal open={modalOpen}
                   noticeId={selectedNoticeId}
                   onClose={onClickCloseModal} />
    </div>
  );
}
