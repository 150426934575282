import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const categoryInventory = [
  '패션/잡화',
  '외식',
  '캐릭터',
  '뷰티',
  '게임',
  'TV 프로그램',
  '커리어',
  '주류',
  '음악/K-pop',
  '명품',
  '애니메이션',
  '웹툰',
  '인테리어',
  '건강/피트니스',
  '전시',
];

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight: personName.includes(name)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

export default function CategorySelects({
                                          categories,
                                          handleCategories
                                        }: {
  categories: string[];
  handleCategories: (event: SelectChangeEvent<typeof categoryInventory>) => void;
}) {
  const theme = useTheme();

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="popupstore-category-label">카테고리</InputLabel>
        <Select
          labelId="popupstore-category-label"
          id="popupstore-category"
          multiple
          value={categories}
          onChange={handleCategories}
          input={<OutlinedInput label="Category" />}
          MenuProps={MenuProps}
        >
          {categoryInventory.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, categories, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}