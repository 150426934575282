import api from '../api';

export interface Notice {
  id: number;
  created_at: string;
  updated_at: string;
  title: string;
  link: string;
  status: string;
  startDate: string;
  startHour: string | null;
  endDate: string;
  endHour: string | null;
}


export const FetchNotices = async (): Promise<Notice[]> => {
  try {
    const { data } = await api.get("/api/admin/v2/notices");

    return data.notices;
  } catch {
    return [];
  }
}