import React, { useEffect, useState } from 'react';
import { Button, Checkbox, FormControlLabel, FormGroup, Grid, MenuItem, Select, TextField } from '@mui/material';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { popupStoreSearchCategories } from '../../types/entities/popup-stores';
import { FetchAllPopupStores } from '../../services/popup-stores.service';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

const usePopupStores = () => {
  const [checkPopupStore, setCheckPopupStore] = useState<boolean>(true);
  const [checkExhibition, setCheckExhibition] = useState<boolean>(true);
  const [searchCategory, setSearchCategory] = useState<string>(popupStoreSearchCategories[0].value);
  const [searchValue, setSearchValue] = useState<string>('');
  const [popupStores, setPopupStores] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [filteredRows, setFilteredRows] = useState<any[]>([]);
  const [checkToOpen, setCheckToOpen] = useState<boolean>(true);
  const [checkOpening, setCheckOpening] = useState<boolean>(true);
  const [checkClosed, setCheckClosed] = useState<boolean>(true);
  const navigate: NavigateFunction = useNavigate();

  useEffect(() => {
    async function fetchAllPopupStores() {
      const fetchedPopupStores = await FetchAllPopupStores();

      setPopupStores(fetchedPopupStores);
    }

    fetchAllPopupStores();
  }, []);

  useEffect(() => {
    if (!popupStores || !popupStores.length) {
      return;
    }

    const newRows = popupStores.map((popupStore: any) => ({
      id: popupStore.id,
      type: popupStore.type,
      name: popupStore.name,
      address: popupStore.address,
      postCount: popupStore.postCount,
      viewCount: popupStore.viewCount,
      openNotificationCount: popupStore.openNotificationCount,
      isAssociated: popupStore.isAssociated,
      startDate: popupStore.startDate,
      endDate: popupStore.endDate,
    }));

    setRows(newRows);
    setFilteredRows(newRows);
  }, [popupStores]);

  const columns: GridColDef[] = [
    {
      field: 'actions',
      headerName: '',
      minWidth: 100,
      renderCell: (params) => (
        <strong>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => {
              navigate(`/popup-stores/${params.id}`);
            }}
          >
            상세보기
          </Button>
        </strong>
      ),
    },
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 50,
    },
    {
      field: 'name',
      headerName: '장소명',
      minWidth: 200,
    },
    {
      field: 'address',
      headerName: '주소',
      minWidth: 300,
    },
    {
      field: 'startDate',
      headerName: '시작일',
      minWidth: 100,
    },
    {
      field: 'endDate',
      headerName: '종료일',
      minWidth: 100,
    },
    {
      field: 'postCount',
      headerName: '게시물 수',
      minWidth: 50,
    },
    {
      field: 'viewCount',
      headerName: '조회 수',
      minWidth: 50,
    },
    {
      field: 'openNotificationCount',
      headerName: '오픈알림신청수',
      minWidth: 50,
    },
    {
      field: 'isAssociated',
      type: 'boolean',
      headerName: '제휴여부',
      minWidth: 100,
    },
  ];


  useEffect(() => {
    const newFilteredRows = rows.filter((row) => {
      if (!checkPopupStore && row.type === 'POPUP_STORE'){
        return false;
      }

      if (!checkExhibition && row.type === 'EXHIBITION') {
        return false;
      }

      if (checkToOpen && dayjs().format('YYYY-MM-DD') < dayjs(row.startDate).format('YYYY-MM-DD')) {
        return true;
      }

      if (checkOpening && row.startDate <= dayjs().format('YYYY-MM-DD') && dayjs().format('YYYY-MM-DD') <= row.endDate) {
        return true
      }

      if (checkClosed && dayjs().format('YYYY-MM-DD') > row.endDate) {
        return true;
      }

      return false;
    });

    setFilteredRows(newFilteredRows);
  }, [checkPopupStore, checkExhibition, checkClosed, checkOpening, checkToOpen, rows]);

  const handleSearch = (category: string, value: string) => {
    if (!value) {
      setFilteredRows(rows);
      return;
    }

    const newFilteredRows = rows.filter((row) => {
      if (category === 'name') {
        return row.name.includes(value);
      }

      if (category === 'address') {
        return row.address.includes(value);
      }

      return false;
    });

    setFilteredRows(newFilteredRows);
  };

  const onClickAddPopupStore = () => {
    navigate('/popup-stores/create');
  }

  return {
    models: {
      searchCategory,
      searchValue,
      columns,
      rows,
      filteredRows,
      checkToOpen,
      checkOpening,
      checkClosed,
      checkPopupStore,
      checkExhibition,
    },
    operations: {
      setSearchCategory,
      setSearchValue,
      handleSearch,
      onClickAddPopupStore,
      setCheckToOpen,
      setCheckOpening,
      setCheckClosed,
      setCheckPopupStore,
      setCheckExhibition,
    },
  };
};

export default function PopupStores() {
  const { models, operations } = usePopupStores();

  return (
    <div className="main-content">
      <div className="section-search">
        <Grid container
              spacing={2}
              mb={1}>

          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                label="팝업스토어"
                checked={models.checkPopupStore}
                onChange={(e, checked) => operations.setCheckPopupStore(checked)}
              />
            </FormGroup>
          </Grid>

          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                label="전시"
                checked={models.checkExhibition}
                onChange={(e, checked) => operations.setCheckExhibition(checked)}
              />
            </FormGroup>
          </Grid>


        </Grid>
        <Grid container
              spacing={2}
              mb={1}>
          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                label="오픈예정"
                checked={models.checkToOpen}
                onChange={(e, checked) => operations.setCheckToOpen(checked)}
              />
            </FormGroup>
          </Grid>

          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                label="운영중"
                checked={models.checkOpening}
                onChange={(e, checked) => operations.setCheckOpening(checked)}
              />
            </FormGroup>
          </Grid>

          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                label="종료"
                checked={models.checkClosed}
                onChange={(e, checked) => operations.setCheckClosed(checked)}
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={10} md={10}>
            <FormGroup row>
              <Select
                value={models.searchCategory}
                onChange={(e) => operations.setSearchCategory(e.target.value as string)}
                displayEmpty
              >
                {popupStoreSearchCategories && popupStoreSearchCategories.length
                  ? popupStoreSearchCategories.map((item) => (
                    <MenuItem
                      key={item.value}
                      className="menu-item"
                      value={item.value}
                    >
                      {item.label}
                    </MenuItem>
                  ))
                  : <></>}
              </Select>
              <TextField
                label="검색할 값을 입력하세요"
                variant="outlined"
                sx={{
                  width: '60%',
                }}
                onChange={(e) => operations.setSearchValue(e.target.value)}
              />
              <Button
                variant="outlined"
                onClick={() => operations.handleSearch(models.searchCategory, models.searchValue)}
              >검색</Button>
            </FormGroup>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              sx={{
                width: '100%',
                height: '100%',
              }}
              onClick={operations.onClickAddPopupStore}
            >
              <AddBusinessIcon /> 추가하기
            </Button>
          </Grid>
        </Grid>
      </div>

      <div
        style={{
          height: '80vh',
          width: '100%',
          marginTop: '10px',
        }}
      >
        <DataGrid
          rows={models.filteredRows}
          columns={models.columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 20 },
            },
          }}
        />
      </div>
    </div>
  );
}
