import React, { useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, FormControlLabel, FormGroup, Grid, Stack } from '@mui/material';

import { CrawlOpenPopupStores } from '../../../services/crawls/crawl-open-popup-stores';
import { CrawlScheduledPopupStores } from '../../../services/crawls/crawl-scheduled-popup-stores';
import { CrawledPopupStoreSummary } from '../../../services/crawls/crawls.type';
import Swal from 'sweetalert2';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { FetchAllPopupStores } from '../../../services/popup-stores.service';
import dayjs from 'dayjs';

interface PopupStoreItem extends CrawledPopupStoreSummary {
  status: string;
}

const useCrawlPopupStores = () => {
  const navigate = useNavigate();
  const [registeredPopupStoreNames, setRegisteredPopupStoreNames] = useState<string[]>([]);
  const [needOnlySeoul, setNeedOnlySeoul] = useState<boolean>(true);
  const [popupStores, setPopupStores] = useState<PopupStoreItem[]>([]);
  const [filterSameName, setFilterSameName] = useState<boolean>(true);
  const today = dayjs().format('YYYY-MM-DD');

  const onClickCrawls = async () => {
    Swal
      .fire({
        title: "정보 가져오는 중",
        text: "약 20초 정도 걸릴 예정입니다.",
        didOpen: async () => {
          Swal.showLoading();

          const crawlResponses = [];
          crawlResponses.push(await CrawlOpenPopupStores());
          crawlResponses.push(await CrawlScheduledPopupStores());

          const newPopupStores: PopupStoreItem[] = [];
          for (let i = 0; i < crawlResponses.length; i++) {
            const status = i === 0 ? "open" : "scheduled";
            const crawlResponse = crawlResponses[i];

            for (const crawled of crawlResponse) {
              newPopupStores.push({ ...crawled, status });
            }
          }
          localStorage.setItem('todayCrawls', JSON.stringify({ date: today, data: JSON.stringify(newPopupStores) }));
          setPopupStores(newPopupStores);
          Swal.close();
        },
      });
    return;
  };
  const onClickDetail = useCallback((id: number) => {
    navigate(`/popup-stores/crawl-${id}`)
  }, [navigate]);

  useEffect(() => {
    async function fetchAllPopupStores() {
      const fetchedPopupStores = await FetchAllPopupStores();

      if (fetchedPopupStores.length === 0) {
        return;
      }

      setRegisteredPopupStoreNames(fetchedPopupStores.map((popupStore: any) => popupStore.name));
    }

    fetchAllPopupStores();
  }, []);

  useEffect(() => {
    const todayCrawls = localStorage.getItem('todayCrawls');
    if (todayCrawls) {
      const parsedTodayCrawls = JSON.parse(todayCrawls);

      if (parsedTodayCrawls.date === today) {
        setPopupStores(JSON.parse(parsedTodayCrawls.data));
      }
    }
  }, [today]);

  return {
    models: {
      popupStores,
      filterSameName,
      registeredPopupStoreNames,
      needOnlySeoul,
    },
    operations: {
      onClickCrawls,
      onClickDetail,
      setFilterSameName,
      setNeedOnlySeoul,
    },
  };
}

function getCards(
  index: number,
  popupStore: PopupStoreItem,
  onClick: (id: number) => void,
) {
  const href = popupStore.href;
  const id = Number(href.split('/popup/')[1]);

  return <Grid container
               key={index}
               sx={{ border: 1, borderColor: 'divider', borderRadius: 1, padding: 1, marginTop: 1 }}>
    <Grid item
          xs={3}
          display='flex'
          justifyContent='center'
          alignItems='center'>
      <img src={popupStore.img} alt={popupStore.title} style={{ width: '30%' }} />
    </Grid>
    <Grid item
          xs={9}>
      <Grid item
            xs={12}>
        {popupStore.title} ({popupStore.startDate.trim()} ~ {popupStore.endDate.trim()})
      </Grid>
      <Grid item
            xs={12}>
        {popupStore.address}
      </Grid>
      <Grid item
            xs={12}
            mt={1}>
        <Button variant='contained'
                onClick={() => onClick(id)}>상세 보기
        </Button>
      </Grid>
    </Grid>
  </Grid>;
}

export default function CrawlPopupStores() {
  const { models, operations } = useCrawlPopupStores();

  return (
    <div className="main-content">
      <Stack>
        <Stack>
          <Grid container>
            <Grid item
                  xs={12}>
              <Button variant="contained"
                      size="large"
                      fullWidth
                      onClick={operations.onClickCrawls}>
                정보 가져오기
              </Button>
            </Grid>
          </Grid>
        </Stack>
        {models.popupStores.length > 0 && (
          <>
            <Grid container>
              <Grid item
                    xs={12}><h2>필터링</h2></Grid>

              <Grid item
                    xs={3}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="동일한 장소 제외"
                    checked={models.filterSameName}
                    onChange={(e, checked) => operations.setFilterSameName(checked)}
                  />
                </FormGroup>
              </Grid>

              <Grid item
                    xs={3}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="오직 서울만"
                    checked={models.needOnlySeoul}
                    onChange={(e, checked) => operations.setNeedOnlySeoul(checked)}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </>
        )}

        {models.popupStores.length > 0 && (
          <Stack mt={3}>
            <Stack>
              <Typography variant="h5">오픈예정</Typography>

              {models
                .popupStores
                .filter((popupStore) => {
                  if (!models.filterSameName) {
                    return true;
                  }

                  return !models.registeredPopupStoreNames.includes(popupStore.title);
                })
                .filter((popupStore) => {
                  if (models.needOnlySeoul) {
                    return popupStore.address.includes('서울');
                  }

                  return true;
                })
                .filter((popupStore) => popupStore.status === 'scheduled')
                .map((popupStore, index) => getCards(index, popupStore, operations.onClickDetail))}
            </Stack>

            <Stack mt={3}>
              <Typography variant="h5">진행중</Typography>
              {models
                .popupStores
                .filter((popupStore) => {
                  if (!models.filterSameName) {
                    return true;
                  }

                  return !models.registeredPopupStoreNames.includes(popupStore.title);
                })
                .filter((popupStore) => {
                  if (models.needOnlySeoul) {
                    return popupStore.address.includes('서울');
                  }

                  return true;
                })
                .filter((popupStore) => popupStore.status === 'open')
                .map((popupStore, index) => getCards(index, popupStore, operations.onClickDetail))}
            </Stack>
          </Stack>
        )}
      </Stack>
    </div>
  );
}