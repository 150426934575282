import api from '../../api';

export interface Retention {
  last: number;
  lastAndCurrent: number;
}

export const GetTodayRetentions = async () => {
  const { data } = await api.get('/api/admin/v2/users/all/statistics/today-retentions');

  return {
    monthRetention: data.monthRetention as Retention,
    weekRetention: data.weekRetention as Retention,
    dayRetention: data.dayRetention as Retention,
  }
}
