import { useCallback, useEffect, useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import api from "services/api";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { NavigateFunction, useNavigate } from "react-router";
import AdBannersModal from "modals/AdBannersModal";

const searchMenuItems = ["이름"];

export default function AdBannersPage() {
  const [selectedSearchCategory, setSelectedSearchCategory] = useState<string>("이름");
  const [searchValue, setSearchValue] = useState<string>("");
  const [banners, setBanners] = useState<any[]>([]);
  const [filteredBanners, setFilteredBanners] = useState<any[]>([]);
  const navigate: NavigateFunction = useNavigate();
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [selectedBanner, setSelectedBanner] = useState<any | null>(null);
  
  const columns: GridColDef[] = [
    {
      field: "actions",
      headerName: "",
      minWidth: 100,
      renderCell: (params) => (
        <strong>
          <Button variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => {
                    setSelectedId(params.id as number);
                    setSelectedBanner(banners.find((banner) => banner.id === params.id));
                    setShowPopup(true);
                  }}>상세보기</Button>
        </strong>
      ),
    },
    {
      field: "id",
      headerName: "ID",
      minWidth: 100,
    },
    {
      field: "name",
      headerName: "캠페인명",
      minWidth: 200,
    },
    {
      field: "type",
      headerName: "랜딩 타입",
      minWidth: 200,
    },
    {
      field: "startDate",
      headerName: "시작일",
      minWidth: 150,
    },
    {
      field: "endDate",
      headerName: "종료일",
      minWidth: 150,
    },
    {
      field: "viewCount",
      headerName: "총 조회수 / 총 조회사용자수",
      minWidth: 200,
    },
    {
      field: "clickCount",
      headerName: "총 클릭수 / 총 클릭사용자수",
      minWidth: 200,
    },
  ];

  const switchType = (banner: any) => {
    switch (banner.type) {
      case "MAIN":
        return "메인";
      case "POPUP_STORE_HOME":
        return `팝업스토어 (${banner.popupStore?.name ?? ""})`;
      case "SPLASH":
        return "스플래쉬";
    }
  };

  const switchLinkType = (type: string) => {
    switch (type) {
      case "POPUP_STORE":
        return "팝업스토어";
      case "EXTERNAL":
        return "외부링크";
      case "NONE":
        return "";
    }
  };

  const fetchAdBanners = useCallback(async () => {
    const { data: { adBanners } } = await api.get("/api/admin/v2/ad-banners");
  
    const newRows = adBanners.map((banner: any) => ({
      id: banner.id,
      name: banner.name,
      type: switchType(banner),
      linkType: switchLinkType(banner.linkType),
      startDate: banner.startDatetime,
      endDate: banner.endDatetime,
      viewCount: `${banner.viewCount} / ${banner.viewUsersCount}`,
      clickCount: `${banner.clickCount} / ${banner.clickUsersCount}`,
    }));
    setBanners(adBanners);
    setFilteredBanners(newRows);
  }, []);

  useEffect(() => {
    fetchAdBanners();
  }, [fetchAdBanners]);

  return (
    <div className="main-content">
      <Grid container
            spacing={2}
            mb={1}>
        <Grid item
              xs={10}
              md={10}>
          <FormGroup row>
            <Select value={selectedSearchCategory}
                    onChange={(e) => setSelectedSearchCategory(e.target.value as string)}
                    displayEmpty>
              {searchMenuItems.map((item) => (<MenuItem key={item} value={item}>{item}</MenuItem>))}
            </Select>
            <TextField label="검색할 값을 입력하세요"
                       variant="outlined"
                       sx={{ width: '60%' }}
                       onChange={(e) => setSearchValue(e.target.value)} />
                       
            <Button variant="outlined"
                    onClick={() => {}}>검색</Button>
          </FormGroup>
        </Grid>


        <Grid item xs={2}>
          <Button variant="contained"
                  color="primary"
                  sx={{
                    width: "100%",
                    height: "100%",
                  }}
                  onClick={() => {
                    setSelectedId(null);
                    setSelectedBanner(null);
                    setShowPopup(true);
                  }}>
            추가하기
          </Button>
        </Grid>
      </Grid>

      <div style={{
             height: '80vh',
             width: '100%',
             marginTop: '10px',
           }}>
        <DataGrid rows={filteredBanners}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 20 },
                    },
                  }} />
      </div>

      <div>
        <AdBannersModal open={showPopup}
                        onClose={() => setShowPopup(false)}
                        selectedBanner={selectedBanner} />
      </div>
    </div>
  );
}
