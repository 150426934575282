import { Button, Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';

interface ThumbnailsProps {
  id: number;
  url: string;
  onDelete: () => void;
}

export default function Thumbnails({
  url,
  onDelete,
}: ThumbnailsProps) {
  return (
    <Grid container xs={6} key={url} mb={1}>
      <Grid item xs={12}>
        <img
          key={`image-${url}`}
          src={url}
          alt='장소 썸네일 이미지'
          style={{
            width: '100%',
            height: '100%'
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          variant='outlined'
          color='error'
          onClick={onDelete}
          startIcon={<DeleteIcon />}
        >삭제하기</Button>
      </Grid>
    </Grid>
  );
}
