import { CategorySelection, EditPlaceDialogProps, EditPlaceForm, LocationCategory } from 'types/place.interface';
import '../styles.scss';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Controller } from 'react-hook-form';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { DialogTitleProps } from 'types/dialog.interface';
import { MenuItem, Select, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import DialogConfirmClose from 'dialog/DialogConfirmClose';
import PlaceService from 'services/place.service';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const EditPlaceDialog = ({
  open,
  data,
  handleClose,
  handleRefresh
}: EditPlaceDialogProps) => {
  const [openDialogX, setOpenDialogX] = useState<boolean>(false);
  const [locationCategory, setLocationCategory] = useState<CategorySelection[]>([]);
  
  const getLocationCategory = async () => {
    const response = await PlaceService.getLocationCategory();
    
    setLocationCategory(
      response?.data?.data?.locationCategory?.map((item: LocationCategory) => ({
        value: item.id,
        label: item.name
      })) ?? []
    );
  };

  useEffect(() => {
    getLocationCategory();
    reset({
      comment: data?.comment ?? '',
      name: data?.name ?? '',
      address: data?.address ?? '',
      locationCategoryId: data?.locationCategory?.id ?? null
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const validationSchema = Yup.object().shape({
    comment: Yup.string().test(
      'len',
      'Comments for members that are limited to 500 characters.',
      (val) => {
        if (val === undefined) {
          return true;
        }
        return val.length <= 500;
      }
    ),
    status: Yup.boolean()
  });

  const defaultValues = {
    comment: '',
    status: false
  };

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues
  };
  const { handleSubmit, formState, control, reset } =
    useForm<EditPlaceForm>(formOptions);
  // const { errors } = formState;
  // const { comment } = errors;

  const onSubmit: SubmitHandler<EditPlaceForm> = async (form) => {
    const response: any = await PlaceService.updatePlace(data?.id, form);
    if (response) {
      handleRefresh();
      handleClose();
    }
  };

  const handleCloseDialogX = () => {
    setOpenDialogX(false);
  };

  const handleCloseDialog = (event: DialogTitleProps, reason: string) => {
    if (reason && reason === 'backdropClick') return;
    handleClose();
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleCloseDialog}
        open={open}
        className="edit-place-dialog"
      >
        <BootstrapDialogTitle
          id="edit-place-dialog"
          onClose={() => formState.isDirty ? setOpenDialogX(true) : handleClose()}
        >
          위치 관리
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <form id="edit-place-form" className="edit-place-form">
            <div className="info-item">
              <p className="label">위치명</p>
              <Controller
                name="name"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="name"
                    onChange={onChange}
                    value={value}
                    className="value"
                    type="text"
                    placeholder="중대원"
                    variant="standard"
                    InputProps={{
                      disableUnderline: true
                    }}
                  />
                )}
              />
            </div>
            <div className="info-item">
              <p className="label">위치 번호</p>
              <p className="value">{data?.id}</p>
            </div>
            <div className="info-item">
              <p className="label">주소</p>
              <Controller
                name="address"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    onChange={onChange}
                    value={value}
                    className="value"
                    type="text"
                    placeholder="경기도 성남시 분당구 하오개로 276"
                    variant="standard"
                    InputProps={{
                      disableUnderline: true
                    }}
                  />
                )}
              />
            </div>
            <div className="info-item">
              <p className="label">신고받은 수</p>
              <p className="value">{data?.reportsNum}</p>
            </div>
            <div className="info-item">
              <p className="label">등록한 아이디</p>
              <p className="value">{data?.registrationAlias}</p>
            </div>
            <div className="info-item">
              <p className="label">카테고리</p>
              <Controller
                name="locationCategoryId"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    id="locationCategoryId"
                    onChange={onChange}
                    value={value ?? ''}
                    className="value"
                  >
                    {locationCategory?.map((item) => (
                      <MenuItem
                        key={item.value}
                        className="menu-item"
                        value={item.value}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </div>
            <div className="info-item">
              <p className="label">게시글 수</p>
              <p className="value">{data?.postNum}</p>
            </div>
            <div className="section-comment">
              <p className="label">관리자 코멘트</p>
              <Controller
                name="comment"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextareaAutosize
                    id="comment"
                    onChange={onChange}
                    value={value}
                    maxRows={8}
                    minRows={8}
                    maxLength={500}
                    placeholder="내용을 입력하세요."
                  />
                )}
              />
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            form="edit-place-form"
            className="save-button"
            onClick={handleSubmit(onSubmit)}
            disabled={!formState.isDirty}
            autoFocus
          >
            수정하기
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <DialogConfirmClose
        open={openDialogX}
        handleOk={handleClose}
        handleClose={handleCloseDialogX}
      />
    </>
  );
};

export default EditPlaceDialog;
