import axios from 'axios';
import { CrawledPopupStoreSummary } from './crawls.type';

interface CrawledPopupStoreSummaries {
  popupStores?: CrawledPopupStoreSummary[];
}

export const CrawlScheduledPopupStores = async (): Promise<CrawledPopupStoreSummary[]> => {
  try {
    const { data } = await axios.get<CrawledPopupStoreSummaries>('http://13.124.2.55:8088/scheduled-popup-stores');

    return data.popupStores ?? [];
  } catch {
    return [];
  }
}
