import { useEffect, useState } from 'react';
import { FormGroup, Grid, MenuItem, Select } from '@mui/material';
import {
  FetchAllPopupStoreOpenNotificationApplications,
  FetchAllPopupStores, OpenNotificationApplication
} from '../../../services/popup-stores.service';
import ExcelDownLoadButton from '../../../components/ExcelDownload/ExcelDownLoadButton';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';

const useOpenNotificationApplications = () => {
  const [allPopupStores, setAllPopupStores] = useState<any[]>([]);
  const [allPopupStoreOpenNotificationApplications, setAllPopupStoreOpenNotificationApplications] = useState<OpenNotificationApplication[]>([]);
  const [selectedPopupStoreId, setSelectedPopupStoreId] = useState<number>(-1);
  const [filteredPopupStoreOpenNotificationApplications, setFilteredPopupStoreOpenNotificationApplications] = useState<OpenNotificationApplication[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [sheetName, setSheetName] = useState<string>("모든 장소");
  const [excelRows, setExcelRows] = useState<string[][]>([]);

  const fileName = `장소_알림신청_${dayjs().format('YYMMDD')}`;
  const headers = ["유저 ID", "닉네임", "전화번호", "장소 ID", "장소명", "오픈일", "장소 설명", "신청일", "보유설탕"];
  const columns: GridColDef[] = [
    {
      field: 'userId',
      headerName: '유저 ID',
      minWidth: 50,
    },
    {
      field: 'nickname',
      headerName: '닉네임',
      minWidth: 100,
    },
    {
      field: 'phoneNumber',
      headerName: '전화번호',
      minWidth: 200,
    },
    {
      field: 'popupStoreId',
      headerName: '장소 ID',
      minWidth: 50,
    },
    {
      field: 'popupStoreName',
      headerName: '장소명',
      minWidth: 200,
    },
    {
      field: 'popupStoreStartDate',
      headerName: '오픈일',
      minWidth: 100,
    },
    {
      field: 'popupStoreDescription',
      headerName: '장소 설명',
      minWidth: 200,
    },
    {
      field: 'applicationDate',
      headerName: '신청일',
      minWidth: 100,
    },
    {
      field: 'sugar',
      headerName: '보유설탕',
      minWidth: 100,
    }
  ];

  useEffect(() => {
    async function fetchAllPopupStores() {
      const fetchedPopupStores = await FetchAllPopupStores(true);

      setAllPopupStores(fetchedPopupStores);
    }

    async function fetchAllPopupStoreOpenNotificationApplications() {
      const fetchedPopupStores = await FetchAllPopupStoreOpenNotificationApplications();

      setAllPopupStoreOpenNotificationApplications(fetchedPopupStores);
    }

    fetchAllPopupStores();
    fetchAllPopupStoreOpenNotificationApplications();
  }, []);

  useEffect(() => {
    if (selectedPopupStoreId === -1) {
      setFilteredPopupStoreOpenNotificationApplications(allPopupStoreOpenNotificationApplications);
      setSheetName("모든 오픈예정 장소");
      return;
    }

    const newFilteredPopupStoreOpenNotificationApplications = allPopupStoreOpenNotificationApplications.filter((application) => application.popupStore.id === selectedPopupStoreId);
    setFilteredPopupStoreOpenNotificationApplications(newFilteredPopupStoreOpenNotificationApplications);

    const popupStoreName = newFilteredPopupStoreOpenNotificationApplications.length === 0 ? "Sheet1" : newFilteredPopupStoreOpenNotificationApplications[0].popupStore.name ;
    setSheetName(`장소 ${popupStoreName}의 오픈예정 신청`);
  }, [allPopupStoreOpenNotificationApplications, selectedPopupStoreId]);

  useEffect(() => {
    const newRows = filteredPopupStoreOpenNotificationApplications.map((application) => ({
      id: application.id,
      userId: application.user.id,
      nickname: application.user.nickname,
      phoneNumber: application.user.phoneNumber,
      popupStoreId: application.popupStore.id,
      popupStoreName: application.popupStore.name,
      popupStoreStartDate: application.popupStore.startDate,
      popupStoreDescription: application.popupStore.description,
      applicationDate: application.applicationDate,
      sugar: application.user.sugar,
    }));
    setRows(newRows);
    setExcelRows(newRows.map((row) => [
      row.userId.toString(),
      row.nickname,
      row.phoneNumber,
      row.popupStoreId.toString(),
      row.popupStoreName,
      row.popupStoreStartDate,
      row.popupStoreDescription,
      row.applicationDate,
      row.sugar.toString(),
    ]));
  }, [filteredPopupStoreOpenNotificationApplications]);

  return {
    models: {
      allPopupStores,
      selectedPopupStoreId,
      allPopupStoreOpenNotificationApplications,
      columns,
      rows,
      fileName,
      sheetName,
      headers,
      excelRows,
    },
    operations: {
      setAllPopupStores,
      setSelectedPopupStoreId,
    },
  }
}

export default function OpenNotificationApplications() {
  const { models, operations } = useOpenNotificationApplications();

  return (
    <div className="main-content">
      <div className="section-search">
        <Grid container
              spacing={2}
              justifyContent="space-between"
              alignItems="flex-end" >
          <Grid item
                xs={4}>
            <FormGroup row>
              <Select
                fullWidth
                value={models.selectedPopupStoreId}
                onChange={(e) => operations.setSelectedPopupStoreId(e.target.value as number)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  PaperProps: {
                    sx: {
                      maxHeight: "450px",
                    },
                  },
                }}
              >
                <MenuItem value={-1} className="menu-item">모든 장소</MenuItem>
                {models.allPopupStores.map((popupStore, index) => (
                  <MenuItem key={`popup-store-${index}`}
                            className="menu-item"
                            value={popupStore.id}>
                    {popupStore.name}
                  </MenuItem>
                ))}
              </Select>
            </FormGroup>
          </Grid>
          <Grid>
            <ExcelDownLoadButton headers={models.headers}
                                 data={models.excelRows}
                                 excelFileName={models.fileName}
                                 sheetName={models.sheetName} />
          </Grid>
        </Grid>
      </div>

      <div style={{
        height: '80vh',
        width: '100%',
        marginTop: '10px',
      }}>
        <DataGrid columns={models.columns}
                  rows={models.rows}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 20 },
                    },
                  }} />
      </div>
    </div>
  );
}
