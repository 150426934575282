import './styles.scss';
import Pagination from '@mui/material/Pagination';
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import ButtonCustom from 'elements/Button';
import { SetStateAction, useEffect, useState } from 'react';
import { Place, PlacePagination } from 'types/place.interface';
import { categories, regions, places, placeHead } from 'types/entities/place';
import PlaceService from 'services/place.service';
import TabelComponent from 'elements/TableCell';
import { DialogState } from 'types/dialog.interface';
import EditPlaceDialog from './components/EditPlaceDialog';

const PlaceManagement = () => {
  const [category, setCategory] = useState<string>('addressUnit');
  const [region, setRegion] = useState<string>('full');
  const [keySearch, setKeySearch] = useState<string>('');
  const [data, setData] = useState<PlacePagination>(places);
  const [placeList, setPlaceList] = useState([]);
  const [openDetailDialog, setOpenDetailDialog] = useState<DialogState<Place>>({
    open: false,
    data: null
  });

  const handleCategoryChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setCategory(event.target.value);
  };
  const handleRegionChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setRegion(event.target.value);
  };
  const handleInputChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setKeySearch(event.target.value);
  };

  const getPlaceList = async () => {
    const response = await PlaceService.getPlaceList({
      page: data?.current_page || 1,
      type: region,
      category: category,
      keyword: keySearch || ''
    });
    setData((prevState: PlacePagination) => {
      return {
        ...prevState,
        total: response?.data?.meta?.pagination?.total,
        total_pages: response?.data?.meta?.pagination?.total_pages,
        data: response?.data?.data
      };
    });
    const bodyTables = response?.data?.data?.locations.map((item: Place) => {
      return {
        id: item.id,
        addressUnit: item?.addressUnit,
        name: item.name,
        address: item?.address,
        registrationId: item?.registrationId,
        reportsNum: item?.reportsNum,
        countShare: item?.countFeedShare ?? 0,
        questionsNum: item?.questionsNum,
        responseRate: item?.responseRate + '%',
        postNum: item?.postNum
      };
    }) as [];
    setPlaceList(bodyTables);
  };

  const handlePaginationChange = (_: {}, p: number) => {
    setData((prevState: PlacePagination) => {
      return { ...prevState, current_page: p };
    });
  };

  const handleSearch = () => {
    if (data.current_page !== 1) {
      setData((prevState: PlacePagination) => {
        return { ...prevState, current_page: 1 };
      });
    } else {
      getPlaceList();
    }
  };

  const showDetail = async (index: number) => {
    const id = data?.data?.locations[index]?.id ?? null;
    const res = await PlaceService.getPlaceByID(id);
    setOpenDetailDialog({
      open: true,
      data: res?.data?.data ?? null
    });
  };

  useEffect(() => {
    getPlaceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.current_page]);

  return (
    <div className="main-content place-management">
      <h4 className="number-reported">
        신고 받은 위치 수 : {data?.data?.totalReportedLocations || 0}
      </h4>
      <div className="place-content">
        <div className="section-search">
          <Grid container spacing={2}>
            <Grid item xs={12} md={10}>
              <div className="search-contain">
                <FormControl sx={{ m: 1 }} className="selection-region">
                  <Select
                    value={region}
                    onChange={handleRegionChange}
                    displayEmpty
                  >
                    {regions && regions.length
                      ? regions.map((item) => (
                          <MenuItem
                            key={item.value}
                            className="menu-item"
                            value={item.value}
                          >
                            {item.label}
                          </MenuItem>
                        ))
                      : ''}
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1 }} className="selection-category">
                  <Select
                    value={category}
                    onChange={handleCategoryChange}
                    displayEmpty
                  >
                    {categories && categories.length
                      ? categories.map((item) => (
                          <MenuItem
                            key={item.value}
                            className="menu-item"
                            value={item.value}
                          >
                            {item.label}
                          </MenuItem>
                        ))
                      : ''}
                  </Select>
                </FormControl>
                <TextField
                  className="input-search"
                  value={keySearch}
                  onChange={handleInputChange}
                  onKeyDown={(e) => {
                    if ((e.code === "Enter" || e.code === "NumpadEnter")) handleSearch();
                  }}
                />
                <Box className="search-button">
                  <ButtonCustom
                    title="검색"
                    cate="standard"
                    onClick={handleSearch}
                  />
                </Box>
              </div>
            </Grid>
            <Grid item xs={12} md={2}>
              <span className="number-searched">(총 : {data?.total}건)</span>
            </Grid>
          </Grid>
        </div>
        <div className="section-place-list">
          <TabelComponent
            dataHead={placeHead}
            databody={placeList}
            actionShow={showDetail}
          />
          <EditPlaceDialog
            open={openDetailDialog.open}
            data={openDetailDialog.data}
            handleClose={() => setOpenDetailDialog({ open: false, data: null })}
            handleRefresh={getPlaceList}
          />
        </div>
        <Pagination
          className="section-pagination"
          count={data?.total_pages || 1}
          page={data?.current_page || 1}
          onChange={handlePaginationChange}
          variant="outlined"
        />
      </div>
    </div>
  );
};

export default PlaceManagement;
