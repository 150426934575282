import CallToActionIcon from '@material-ui/icons/CallToAction';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import PersonIcon from '@material-ui/icons/Person';
import PublicIcon from '@material-ui/icons/Public';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import StoreIcon from '@mui/icons-material/Store';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import { Sidebar } from 'types/sidebar.interface';

export const titleConfig: {
  [key: string]: string;
} = {
  "/member-information": "회원 관리",
  "/place-management": "장소 관리",
  "/pmoji-management": "프모지 관리",
  "/content-management": "콘텐츠 관리",
  "/admin-account": "관리자 계정",
  "/users/sugars": "설탕 관리",
  "/users/reports": "신고 관리 (WIP)",
  "/popup-stores": "팝업 스토어 관리",
  "/popup-stores/crawls": "팝업스토어 크롤링",
  "/popup-stores/create": "팝업 스토어 생성",
  "/popup-stores/open-notification-application": "오픈 예정 알림신청 목록",
  "/prendies": "프렌디 신청목록",
  "/dashboards": "대시보드",
};

const sidebarConfig: Sidebar[] = [
  {
    id: '1',
    title: '회원 관리',
    icon: <PersonIcon />,
    path: '/member-information',
    roles: ['ADMIN', 'SUPER_ADMIN']
  },
  {
    id: '8',
    title: '프렌디 신청목록',
    icon: <SensorOccupiedIcon />,
    path: '/prendies',
    roles: ['ADMIN', 'SUPER_ADMIN']
  },
  {
    id: '2',
    title: '장소 관리',
    icon: <PublicIcon />,
    path: '/place-management',
    roles: ['ADMIN', 'SUPER_ADMIN']
  },
  {
    id: '7',
    title: '팝업 스토어 관리',
    icon: <StoreIcon />,
    path: '/popup-stores',
    roles: ['SUPER_ADMIN', 'ADMIN']
  },
  {
    id: '3',
    title: '프모지 관리',
    icon: <EmojiEmotionsIcon />,
    path: '/pmoji-management',
    roles: ['ADMIN', 'SUPER_ADMIN']
  },
  {
    id: '4',
    title: '콘텐츠 관리',
    icon: <CallToActionIcon />,
    path: '/content-management',
    roles: ['ADMIN', 'SUPER_ADMIN']
  },
  {
    id: '6',
    title: '설탕 관리',
    icon: <LoyaltyIcon />,
    path: '/users/sugars',
    roles: ['SUPER_ADMIN', 'ADMIN']
  },
  {
    id: '5',
    title: '관리자 계정',
    icon: <SupervisorAccountIcon />,
    path: '/admin-account',
    roles: ['SUPER_ADMIN']
  },
];

export default sidebarConfig;
